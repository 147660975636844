import React from 'react';
import Block from "../Block";
import BlockSection from "../BlockSection";
import SignUpCompanyLeadForm from "../../forms/instances/SignUpCompanyLeadForm/SignUpCompanyLeadForm";
import Title from "../../components/typography/Title";

import enMessages from '../../i18n/pricing/en.json'
import ruMessages from '../../i18n/pricing/ru.json'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const PricingBlock = ({ locale }) => {

    const [ active, setActive ] = React.useState(0);

    const openTab = e => setActive(+e.target.dataset.index);

    const TabContent = ({ title, className, content }) => (
      <div className={`tabcontent tabcontent_${className} tabcontent_tabs${content.schemeType}`}>
            {content.blocks.map((t, i) => (
                <div className={`tabcontent_item tabcontent_item_${t.blockColor}`} key={i}>
                    <div className="tabcontent_item_content">
                      <div className="tabcontent_item_content_top">
                        <div className="pricingBlockTitle">{t.blockName}</div>
                        {t.value &&
                            <div className="pricingBlockValue">
                                {t.value.digit && <span className="pricingBlockValue-Digit">{t.value.digit}</span>}
                                {t.value.percent && <span className="pricingBlockValue-Percent">{t.value.percent}</span>}
                                {t.value.ruble && <span className="pricingBlockValue-Ruble">{t.value.ruble}</span>}
                                {t.value.description && <span className="pricingBlockValue-Description">{t.value.description}</span>}
                                {t.value.comment && <div className="pricingBlockValue-Comment">{t.value.comment}</div>}
                            </div>
                        }

                        {t.featureList &&
                            <ul className="featureList">
                                {t.featureList.map((f, i) => (
                                    <li className={`${t.blockColor} ${f.nonMarkered && 'nonmarkered'}`} dangerouslySetInnerHTML={{ __html: f.item }} key={i} />
                                ))}
                            </ul>
                        }
                      </div>
                      <div className="tabcontent_item_content_bottom">
                        {t.comment &&
                          <div className="comment" dangerouslySetInnerHTML={{ __html: t.comment }} />
                        }
                      </div>
                    </div>
                </div>
            ))}
      </div>
    );

    return (
        <Block config={{
            baseBorder: true
        }}>
            <div className="container tariffsContainer">
                <BlockSection>

                    <div className="tariffs">
                      <div className="tabs_container">
                          <div className={`tab tab_${locale}`}>
                            {messages[locale].tabs.map((n, i) => (
                              <button
                                className={`tablinks ${i === active ? 'active' : ''} tabbtn_${n.className}`}
                                onClick={openTab}
                                data-index={i}
                                key={i}
                              >{n.title}</button>
                            ))}
                          </div>
                      </div>
                      {messages[locale].tabs[active] && <TabContent {...messages[locale].tabs[active]} />}
                    </div>

                </BlockSection>
                <BlockSection className="pricingFOS">
                    {locale === 'ru' && <Title headerStyleNumb="9" level="h2" className="Block-Title-FOS">{messages[locale].letsTalk ? messages[locale].letsTalk : 'Оставить заявку'}</Title>}
                    {messages[locale].textDescription && <div className="Block-Description-FOS">{messages[locale].textDescription}</div>}
                    <div className="Block-Form">
                        <SignUpCompanyLeadForm textSubmit={messages[locale].submitText} canBeFixed="true" />
                    </div>
                </BlockSection>
            </div>
        </Block>
    )
};

export default PricingBlock;
