import React from 'react';
import Layout from "../layout/Layout";
import PricingBlock from "../blocks/static/PricingBlock";
import SEO from "../components/seo";

import enMessages from '../i18n/pricing/en.json'
import ruMessages from '../i18n/pricing/ru.json'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const PricingPage = ({ pathContext: { locale } }) => {
    return (
        <Layout locale={locale}>
            <SEO
                title={messages[locale].seo.title}
                description={messages[locale].seo.description}
            />
            <PricingBlock locale={locale}/>
        </Layout>
    )
};

export default PricingPage;
